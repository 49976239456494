import "./index.css";

import {
  Cascader,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  notification,
} from "antd";
import { CheckCircleFilled, CheckOutlined } from "@ant-design/icons";
import { City, Country, State } from "country-state-city";
import {
  Login,
  UserEmailCode,
  checkPromoCode,
  checkPromoCodeAuthenticated,
  getAllChallengesV2,
  getPaymentMethodWithCurrencies,
  getProfile,
  submitChallengePurchaseCheckout,
  submitChallengePurchaseStep1,
  submitDeposits,
  getAllAuthChallengesV2,
} from "../../API";
import { useEffect, useState } from "react";

import { Button } from "Components/ui/button";
import { useAuth } from "context/AuthContext";
import { useLocation } from "react-router-dom";

const getCountryList = () => Country.getAllCountries();
const getCountryCodeByName = (countryName) =>
  getCountryList().find(
    (x) => x.name?.toLowerCase?.() === countryName?.toLowerCase?.()
  )?.isoCode;
const getStateList = (countryCode) => State?.getStatesOfCountry(countryCode);
const getStateCodeByName = (countryCode, stateName) =>
  getStateList(countryCode).find(
    (x) => x.name?.toLowerCase?.() === stateName?.toLowerCase?.()
  )?.isoCode;
const getCityList = (countryCode, stateCode) =>
  City.getCitiesOfState(countryCode, stateCode);

function PurchaseChallenge() {
  const basePath = process.env.REACT_APP_BASE_PATH; 
  const [formPurchaseChallenge] = Form.useForm();
  const [formLogin] = Form.useForm();

  const { user, login } = useAuth();
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedStateCode, setSelectedStateCode] = useState(null);
  const [filteredStateList, setFilteredStateList] = useState([]);
  const [filteredCityList, setFilteredCityList] = useState([]);

  const [pricePay, setPricePay] = useState(0);

  const [allChallengeData, setAllChallengeData] = useState({});
  const [tradingCapitalAddOnsData, setTradingCapitalAddOnsData] = useState({});

  const [triggerGetTradingCapitalData, setTriggerGetTradingCapitalData] =
    useState(false);
  const [triggerCalculatePricePay, setTriggerCalculatePricePay] =
    useState(false);

  const [selectedChallengeData, setSelectedChallengeData] = useState({});
  const [formInfo, setFormInfo] = useState({});
  const [profileInfo, setProfileInfo] = useState({});

  const [isApplyPromoCode, setIsApplyPromoCode] = useState(false);
  const [promoCodeDeductAmount, setPromoCodeDeductAmount] = useState(0);

  const [toast, contextHolder] = notification.useNotification();
  const [modal, modalContextHolder] = Modal.useModal();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referral = queryParams.get("ref");

  const urlParamsObject = {};
  queryParams.forEach((value, key) => {
    urlParamsObject[key] = value;
  });
  const [urlParamsObj, setUrlParamsObj] = useState({});

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [selectedPaymentMethodCurrency, setSelectedPaymentMethodCurrency] =
    useState({});

  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  const [activeStep, setActiveStep] = useState(1);
  const steps = [
    {
      order: 1,
      label: "Account Detail",
    },
    {
      order: 2,
      label: "Review Your Order",
    },
    {
      order: 3,
      label: " Pay & and Get Challenge",
    },
  ];
  const refundableFeeHintObj = {
    100: "Pass assessment, get full refund from the initial price with your first profit share.",
    125: "Participate to get 25% extra bonus from the initial pricing, triple returns and full refund upon passing the assessment.",
  };
  const [refundableFeeHint, setRefundableFeeHint] = useState("");
  // const sampleTradingCapitalData = [5000, 10000, 25000, 50000, 100000, 200000];
  // const sampleTradingCapitalData = [5000, 10000, 25000];
  const sampleTradingCapitalData = [];
  const samplePlatFormData = ["MT5"];

  const handleCountryChange = (value) => {
    const myCountryCode = !!value
      ? getCountryCodeByName([...value]?.pop?.())
      : null;
    if (selectedCountryCode !== myCountryCode) {
      setSelectedCountryCode(myCountryCode);
      setSelectedStateCode(null);
      formPurchaseChallenge.setFieldValue("state", "");
      formPurchaseChallenge.setFieldValue("city", "");
    }
  };

  const handleStateChange = (value) => {
    const myStateCode = !!value
      ? getStateCodeByName(selectedCountryCode, [...value]?.pop?.())
      : null;
    if (selectedStateCode !== myStateCode) {
      setSelectedStateCode(myStateCode);
      formPurchaseChallenge.setFieldValue("city", "");
    }
  };

  const handleCityChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const handleSelectedChallengeData = async (value, selected_type) => {
    if (Object.keys(tradingCapitalAddOnsData).length > 0) {
      // clear previos addon data
      setTradingCapitalAddOnsData({});
      setRefundableFeeHint("");
    }

    if (Object.keys(selectedChallengeData).length === 0) {
      setSelectedChallengeData({ [selected_type]: value });
    } else {
      if (
        selectedChallengeData?.challenge_type &&
        selected_type === "challenge_type" &&
        selectedChallengeData?.challenge_type !== value
      ) {
        setSelectedChallengeData({});
        setSelectedChallengeData({ [selected_type]: value });
        // set the price to 0
        setPricePay(0);
      } else {
        setSelectedChallengeData((prevState) => ({
          ...prevState,
          [selected_type]: value,
        }));

        if (selected_type === "trading_capital_id") {
          setSelectedChallengeData((prevState) => ({
            ...prevState,
            platform: "MT5",
          }));

          setTriggerGetTradingCapitalData(true);
        }
      }
    }
  };

  const handleGetSelectedTradingCapitalData = () => {
    if (
      selectedChallengeData?.trading_capital_id &&
      selectedChallengeData?.challenge_type
    ) {
      let selectedChallengeTypeData =
        allChallengeData[selectedChallengeData.challenge_type];
      if (selectedChallengeTypeData) {
        let selectedTradingCapitalData = selectedChallengeTypeData.find(
          (item) => item.id === selectedChallengeData.trading_capital_id
        );

        if (selectedTradingCapitalData?.price) {
          setSelectedChallengeData((prevState) => ({
            ...prevState,
            price: selectedTradingCapitalData?.price,
          }));
        }

        if (selectedTradingCapitalData?.account_balance) {
          setSelectedChallengeData((prevState) => ({
            ...prevState,
            account_balance: selectedTradingCapitalData?.account_balance,
          }));
        }

        if (selectedTradingCapitalData?.title) {
          setSelectedChallengeData((prevState) => ({
            ...prevState,
            title: selectedTradingCapitalData?.title,
          }));
        }

        if (selectedTradingCapitalData?.addons) {
          setTradingCapitalAddOnsData(selectedTradingCapitalData.addons);

          let addonsData = selectedTradingCapitalData.addons;
          if (Object.keys(addonsData).length > 0) {
            let defaultAddons = {};
            for (const key in addonsData) {
              // Filter the addons based on is_default property
              let defaultAddon;
              if (urlParamsObject[key]) {
                defaultAddon = addonsData[key].find(
                  (item) => item.id === parseInt(urlParamsObject[key])
                );
              } else {
                defaultAddon = addonsData[key].find(
                  (addon) => addon.is_default === 1
                );
              }

              // If a default addon is found, add it to the defaultAddons object
              if (defaultAddon) {
                switch (key) {
                  case "profit_target_step_two":
                    defaultAddons[key] = {
                      id: defaultAddon.id,
                      name: defaultAddon.name,
                      [key]: defaultAddon["profit_target"],
                      price: defaultAddon.price,
                    };
                    break;
                  default:
                    defaultAddons[key] = {
                      id: defaultAddon.id,
                      name: defaultAddon.name,
                      [key]: defaultAddon[key],
                      price: defaultAddon.price,
                    };
                    break;
                }
                // defaultAddons[key] = {
                //     "id": defaultAddon.id,
                //     "name": defaultAddon.name,
                //     [key]: defaultAddon[key],
                //     "price": defaultAddon.price,
                // };
                if (key === "refundable_fee") {
                  setRefundableFeeHint(
                    refundableFeeHintObj[defaultAddon[key].toString()]
                  );
                }
              }
            }
            setSelectedChallengeData((prevState) => ({
              ...prevState,
              addons: defaultAddons,
            }));
            setTriggerCalculatePricePay(true);
          }
        }

        setTriggerGetTradingCapitalData(false);
      }
    }
  };

  const handleSelectedAddOnData = (add_on_id, add_on_key, add_on_index) => {
    let addOnData = tradingCapitalAddOnsData[add_on_key];
    let selectedAddOnData = addOnData.find((item) => item.id === add_on_id);
    let newSelectedAddon = {};
    if (selectedAddOnData) {
      switch (add_on_key) {
        case "profit_target_step_two":
          newSelectedAddon = {
            id: selectedAddOnData.id,
            name: selectedAddOnData.name,
            [add_on_key]: selectedAddOnData["profit_target"],
            price: selectedAddOnData.price,
          };
          break;
        default:
          newSelectedAddon = {
            id: selectedAddOnData.id,
            name: selectedAddOnData.name,
            [add_on_key]: selectedAddOnData[add_on_key],
            price: selectedAddOnData.price,
          };
          break;
      }
      // newSelectedAddon = {
      //     "id": selectedAddOnData.id,
      //     "name": selectedAddOnData.name,
      //     [add_on_key]: selectedAddOnData[add_on_key],
      //     "price": selectedAddOnData.price,
      // };
    }
    setSelectedChallengeData((prevState) => ({
      ...prevState,
      addons: {
        ...prevState.addons, // Preserve other properties of addons
        [add_on_key]: newSelectedAddon, // Dynamically update the addons object
      },
    }));

    if (
      add_on_key === "profit_target" &&
      selectedChallengeData.challenge_type === "step2"
    ) {
      let tempAddonsData = tradingCapitalAddOnsData["profit_target_step_two"];
      let tempSelectedAddOnData = tempAddonsData[add_on_index];
      let tempNewSelectedAddon = {
        id: tempSelectedAddOnData.id,
        name: tempSelectedAddOnData.name,
        profit_target_step_two: tempSelectedAddOnData["profit_target"],
        price: tempSelectedAddOnData.price,
      };
      setSelectedChallengeData((prevState) => ({
        ...prevState,
        addons: {
          ...prevState.addons, // Preserve other properties of addons
          profit_target_step_two: tempNewSelectedAddon, // Dynamically update the addons object
        },
      }));
    }

    if (add_on_key === "refundable_fee") {
      setRefundableFeeHint(
        refundableFeeHintObj[selectedAddOnData.refundable_fee.toString()]
      );
    }

    setTriggerCalculatePricePay(true);
  };

  const handleCalculatePricePay = () => {
    let price = selectedChallengeData?.price ?? 0;
    let addons = selectedChallengeData?.addons ?? {};
    if (Object.keys(addons).length > 0) {
      for (const key in addons) {
        price = price + addons[key].price;
      }
    }
    setTriggerCalculatePricePay(false);
    setPricePay(price);
  };

  const onClickNextStep = async (values) => {
    if (activeStep === 1) {
      if (Object.keys(selectedChallengeData).length > 0) {
        if (selectedChallengeData?.trading_capital_id) {
          let tempFormValues = {
            ...values,
            country: Array.isArray(values.country)
              ? values.country?.pop?.()
              : values.country,
            state: Array.isArray(values.state)
              ? values.state?.pop?.()
              : values.state,
            city: Array.isArray(values.city)
              ? values.city?.pop?.()
              : values.city,
          };

          setFormInfo(tempFormValues);
          setActiveStep(2);
        } else {
          toast.error({
            message: "",
            description: "Please select your Trading Capital.",
            placement: "top",
          });
        }
      } else {
        toast.error({
          message: "",
          description: "Please select your challenge type.",
          placement: "top",
        });
      }
    }

    if (activeStep === 2) {
      setActiveStep(3);
    }

    if (activeStep === 3) {
      if (isLoggedIn) {
        // Logged In
        challengePurchaseCheckout();
      } else {
        let postObj = {
          challenge: selectedChallengeData?.trading_capital_id ?? "",
          email: formInfo?.email_address ?? "",
          auth_code: formInfo?.verification_code ?? "",
          password: formInfo?.password ?? "",
          first_name: formInfo?.first_name ?? "",
          last_name: formInfo?.last_name ?? "",
          address: formInfo?.address_line ?? "",
          city: formInfo?.city ?? "",
          state: formInfo?.state ?? "",
          country: formInfo?.country ?? "",
          zip: formInfo?.postal_code ?? "",
          promocode: formInfo?.promo_code ?? "",
          affiliate_code: isLoggedIn ? "" : formInfo?.affiliate_code || "",
        };

        let tempAddOnsArray = [];
        let addons = selectedChallengeData?.addons ?? {};
        if (Object.keys(addons).length > 0) {
          for (const key in addons) {
            tempAddOnsArray.push(addons[key].id);
          }
        }
        let tempObj = {
          addons: tempAddOnsArray,
        };

        postObj = { ...postObj, ...tempObj };

        try {
          let result = await submitChallengePurchaseStep1(postObj);
          let isSuccess = result?.success ?? false;
          if (isSuccess) {
            let tempValues = {
              email: formInfo.email_address,
              password: formInfo.password,
            };
            await submitLoginForm(tempValues, "create");
            await challengePurchaseCheckout();
          } else {
            toast.error({
              message: "",
              description: "Purchase Challenge Failed!",
              placement: "top",
            });
          }
        } catch (error) {
          // toast.error({
          //   message: "",
          //   description: "Purchase Challenge Failed!",
          //   placement: "top",
          // });
          if (error?.response?.data?.code) {
            toast.error({
              message: "",
              description: (error?.response?.data?.message && (error.response.data.message).length > 0) ? error.response.data.message : "Purchase Challenge Failed!",
              placement: "top",
            });
          } else {
            toast.error({
              message: "",
              description: "Purchase Challenge Failed!",
              placement: "top",
            });
          } 
          console.error("Error submit purchase challenge step1:", error);
        }
      }
    }
  };

  const challengePurchaseCheckout = async () => {
    let isError = false;
    let checkPaymentMethod = true;  

    if (selectedChallengeData.trading_capital_id == 1) {
      checkPaymentMethod = false;
    }

    if (checkPaymentMethod) {
      if (Object.keys(selectedPaymentMethod).length > 0) {
        if (!(Object.keys(selectedPaymentMethodCurrency).length > 0)) {
          toast.error({
            message: "",
            description: "Please select your payment method currency.",
            placement: "top",
            style: {
              width: 420,
            },
          });

          isError = true;
        } 
      } else {
        toast.error({
          message: "",
          description: "Please select your payment method.",
          placement: "top",
        });

        isError = true;
      }
    } 

    if (!isError) {
      let postObj = {
        challenge: selectedChallengeData?.trading_capital_id ?? "",
        promocode: isApplyPromoCode ? formInfo?.promo_code ?? "" : "",
        // payment_method_id: selectedPaymentMethod?.id ?? "",
        // currency_id: selectedPaymentMethodCurrency?.currency_id ?? "",
        payment_method_id: (selectedChallengeData.trading_capital_id == 1) ? "" : (selectedPaymentMethod?.id ?? ""),
        currency_id: (selectedChallengeData.trading_capital_id == 1) ? "" : (selectedPaymentMethodCurrency?.currency_id ?? ""),
      };

      let tempAddOnsArray = [];
      let addons = selectedChallengeData?.addons ?? {};
      if (Object.keys(addons).length > 0) {
        for (const key in addons) {
          tempAddOnsArray.push(addons[key].id);
        }
      }
      let tempObj = {
        addons: tempAddOnsArray,
      };
      
      postObj = { ...postObj, ...tempObj };
    
      try {
        let result = await submitDeposits(postObj);
        if (result) {
          let depositData = result?.deposit ?? {};
          let paymentMethodId = depositData?.payment_method_id ?? "";
          let data = result?.data ?? {};
          let isTrial = result?.is_trial ?? false;
          if (isTrial) {
            let txnId = depositData.transaction_id;
            window.location.href = `${basePath}/challenge/purchase/status/${txnId}`;
          } else {
            if (paymentMethodId == 6) {
              // payment method id = 6 【EeziePay】
              let formData = data;
              const form = document.createElement("form");
              form.action = "https://gogomart168com.com/fundtransfer.php";
              form.method = "POST";
              // form.target = "_blank";

              for (const key in formData) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = formData[key];
                form.appendChild(input);
              }

              document.body.appendChild(form);
              form.submit();
            } else {
              let redirect_url = data?.url ?? "";
              if (redirect_url.length > 0) {
                window.location.href = redirect_url;
              }
            }
          }
        }
      } catch (error) {
        let errorMessage = "Purchase Challenge Failed!";
        if (error?.response?.data?.code || error?.response?.data?.message) {
          errorMessage = (error?.response?.data?.message && (error.response.data.message).length > 0) ? error.response.data.message : "Purchase Challenge Failed!"
        } 
        modal.error({
          title: "Error!",
          content: (
            <div>
              <div>
                {errorMessage}
              </div>
            </div>
          ),
          okText: "OK",
          centered: true,
          okButtonProps: { className: "modal-ok-button" },
          onOk() {},
        });
        console.error("Error submit purchase challenge:", error);
      } 
    } 

    // if (Object.keys(selectedPaymentMethod).length > 0) {
    //   if (Object.keys(selectedPaymentMethodCurrency).length > 0) {
    //     let postObj = {
    //       challenge: selectedChallengeData?.trading_capital_id ?? "",
    //       promocode: formInfo?.promo_code ?? "",
    //       payment_method_id: selectedPaymentMethod?.id ?? "",
    //       currency_id: selectedPaymentMethodCurrency?.currency_id ?? "",
    //     };

    //     let tempAddOnsArray = [];
    //     let addons = selectedChallengeData?.addons ?? {};
    //     if (Object.keys(addons).length > 0) {
    //       for (const key in addons) {
    //         tempAddOnsArray.push(addons[key].id);
    //       }
    //     }
    //     let tempObj = {
    //       addons: tempAddOnsArray,
    //     };

    //     postObj = { ...postObj, ...tempObj };

    //     try {
    //       let result = await submitDeposits(postObj);

    //       if (result) {
    //         let depositData = result?.deposit ?? {};
    //         let paymentMethodId = depositData?.payment_method_id ?? "";
    //         let data = result?.data ?? {};
    //         if (paymentMethodId == 6) {
    //           // payment method id = 6 【EeziePay】
    //           let formData = data;
    //           const form = document.createElement("form");
    //           form.action = "https://gogomart168com.com/fundtransfer.php";
    //           form.method = "POST";
    //           // form.target = "_blank";

    //           for (const key in formData) {
    //             const input = document.createElement("input");
    //             input.type = "hidden";
    //             input.name = key;
    //             input.value = formData[key];
    //             form.appendChild(input);
    //           }

    //           document.body.appendChild(form);
    //           form.submit();
    //         } else {
    //           let redirect_url = data?.url ?? "";
    //           if (redirect_url.length > 0) {
    //             window.location.href = redirect_url;
    //           }
    //         }
    //       }
    //     } catch (error) {
    //       modal.error({
    //         title: "Error!",
    //         content: (
    //           <div>
    //             <div>
    //               {error?.response?.data?.message ??
    //                 "Purchase Challenge Failed"}
    //             </div>
    //           </div>
    //         ),
    //         okText: "OK",
    //         centered: true,
    //         okButtonProps: { className: "modal-ok-button" },
    //         onOk() {},
    //       });
    //       console.error("Error submit purchase challenge:", error);
    //     }
    //   } else {
    //     toast.error({
    //       message: "",
    //       description: "Please select your payment method currency.",
    //       placement: "top",
    //       style: {
    //         width: 420,
    //       },
    //     });
    //   }
    // } else {
    //   toast.error({
    //     message: "",
    //     description: "Please select your payment method.",
    //     placement: "top",
    //   });
    // }


    // let postObj = {
    //     challenge: selectedChallengeData?.trading_capital_id ?? "",
    //     payment_method_id: 3,
    //     promocode: "",
    //     address: formInfo?.address_line ?? "",
    //     city: formInfo?.city ?? "",
    //     state: formInfo?.state ?? "",
    //     country: formInfo?.country ?? "",
    //     zip: formInfo?.postal_code ?? "",
    // };

    // let tempAddOnsArray = [];
    // let addons = selectedChallengeData?.addons ?? {};
    // if (Object.keys(addons).length > 0) {
    //     for (const key in addons) {
    //         tempAddOnsArray.push(addons[key].id);
    //     }
    // }
    // let tempObj = {
    //     addons: tempAddOnsArray
    // }

    // postObj = { ...postObj, ...tempObj };

    // try {
    //     let result = await submitChallengePurchaseCheckout(postObj);
    //     if (result) {
    //         let redirect_url = result?.url ?? "";
    //         if (redirect_url.length > 0) {
    //             window.location.href = redirect_url;
    //         }
    //     }
    // } catch (error) {
    //     toast.error({
    //         message: "",
    //         description: error?.response?.data?.message ?? "Purchase Challenge Failed",
    //         placement: "top",
    //     });
    //     console.error('Error submit purchase challenge:', error);
    // }
  };

  const handleSendVerificationCode = async () => {
    const emailAddress = formPurchaseChallenge.getFieldValue("email_address");
    if (emailAddress) {
      if (isValidEmail(emailAddress)) {
        try {
          const response = await UserEmailCode({ email: emailAddress });
          if (response) {
            if (response.success) {
              toast.success({
                message: "",
                description: "Send Verification Code Successfully!",
                placement: "top",
              });
            } else {
              toast.error({
                message: "",
                description:
                  response?.message ?? "Send Email Verification Code Failed!",
                placement: "top",
              });
            }
          }
        } catch (error) {
          toast.error({
            message: "",
            description: "Send Email Verification Code Failed!",
            placement: "top",
          });
          console.error("Error Send Email Auth Code:", error);
        }
      } else {
        toast.error({
          message: "",
          description: "Invalid Email Address!",
          placement: "top",
        });
      }
    } else {
      toast.error({
        message: "",
        description: "Email Address is Required.",
        placement: "top",
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleShowLoginModal = () => {
    showLoginModal();
  };

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const showLoginModal = () => {
    setIsLoginModalOpen(true);
  };
  const handleOk = () => {
    formLogin.submit();
    // setIsLoginModalOpen(false);
  };
  const handleCancel = () => {
    setIsLoginModalOpen(false);
  };

  const handleLoginForm = async (values) => {
    let tempValues = {
      email: values.login_email_address,
      password: values.login_password,
    };
    submitLoginForm(tempValues, "login");
    setIsLoginModalOpen(false);
  };

  const submitLoginForm = async (objValues, fromType) => {
    let toastMessage = "";
    if (fromType == "login") {
      toastMessage = "Login Account Successfully";
    }
    if (fromType == "create") {
      toastMessage = "Create Account Successfully";
    }

    try {
      const response = await Login(objValues);
      if (response) {
        login(response.user, response.token);
        toast.success({
          message: "",
          description: toastMessage,
          placement: "top",
        });
        setLoggedIn(checkLoggedIn());
        getAllChallengesData();
      } else {
        toast.error({
          message: "",
          description: "Login Failed",
          placement: "top",
        });
      }
    } catch (error) {
      toast.error({
        message: "",
        description: "Login Failed",
        placement: "top",
      });
      console.error("Error Login:", error);
    }
  };

  const displayChallengeType = (key) => {
    switch (key) {
      case "step1":
        return "One-Step";
      case "step2":
        return "Two-Step";
      case "trial": 
        return "Trial";
      default:
        return key;
    }
  };

  const keyTextToLabel = (key) => {
    // Split the key into words based on underscores
    const words = key.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words with spaces
    return capitalizedWords.join(" ");
  };

  const onChangePaymentMethod = (e) => {
    let selectedPaymentMethodData = paymentMethods.find(
      (item) => item.id === e.target.value
    );
    setSelectedPaymentMethod(selectedPaymentMethodData);
    setSelectedPaymentMethodCurrency({});
  };

  const handleSelectedPaymentMethodCurrency = (value) => {
    let paymentMethodSupportedCurrencyData =
      selectedPaymentMethod?.payment_method_supported_currencies ?? [];
    if (paymentMethodSupportedCurrencyData.length > 0) {
      let selectedPaymentMethodCurrencyData =
        paymentMethodSupportedCurrencyData.find(
          (item) => item.currency_id === value
        );
      setSelectedPaymentMethodCurrency(selectedPaymentMethodCurrencyData);
    }
  };

  const getAllChallengesData = async () => {
    let allChallengeDataResult;
    if (checkLoggedIn()) { 
      allChallengeDataResult = await getAllAuthChallengesV2();
      // allChallengeDataResult = await getAllChallengesV2();
    } else {
      allChallengeDataResult = await getAllChallengesV2();
    }
    const data = allChallengeDataResult?.data ?? {};
    if (Object.keys(data).length > 0) {
      setAllChallengeData(data);
    }
  };

  const fetchDataAndSetProfileData = async () => {
    try {
      const data = await getProfile();
      setProfileInfo(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const checkLoggedIn = () => !!(user || localStorage.getItem("token"));

  const handleGetPaymentMethodWithCurrencies = async () => {
    try {
      const data = await getPaymentMethodWithCurrencies();
      let paymentMethodWithCurrencies =
        data?.payment_method_with_currencies ?? [];
      setPaymentMethods(paymentMethodWithCurrencies);
    } catch (error) {
      console.error("Error fetching Payment Method with currencies:", error);
    }
  };

  async function handleUrlParams() {
    if (urlParamsObject["step"]) {
      let tempStep = urlParamsObject["step"].trim();
      let tempStepChallengeData = allChallengeData[tempStep] ?? [];
      if (tempStepChallengeData.length > 0) {
        await handleSelectedChallengeData(
          urlParamsObject["step"],
          "challenge_type"
        );
        setUrlParamsObj(urlParamsObject);
      }
    }
  }

  const handlePromoCode = async (e) => {
    e.preventDefault();
    let promo_code = formPurchaseChallenge.getFieldValue("promo_code") || "";
    promo_code = promo_code.trim();
    if (promo_code.length > 0) {
      try {
        let result;
        if (isLoggedIn) {
          result = await checkPromoCodeAuthenticated(promo_code);
        } else {
          result = await checkPromoCode(promo_code);
        }
        if (result) {
          let price_type = result?.type ?? null;
          let price_amount = result?.amount ?? null;

          if (price_type !== null && price_amount !== null) {
            // If type is 0 then price - amount
            // if type is 1 then price - amount percentage
            // Eg. amount is 10 then price - 10%
            setIsApplyPromoCode(true);
            price_amount = parseFloat(price_amount);
            switch (parseInt(price_type)) {
              case 0:
                setPricePay(pricePay - price_amount);
                setPromoCodeDeductAmount(price_amount);
                break;
              case 1:
                setPricePay(pricePay - (price_amount / 100) * pricePay);
                setPromoCodeDeductAmount((price_amount / 100) * pricePay);
                break;
              default:
                break;
            }

            toast.success({
              message: "",
              description: "Promo code applied successfully!",
              placement: "top",
            });
          } else {
            toast.error({
              message: "",
              description: "Promo code apply Failed!",
              placement: "top",
            });
          }
        }
      } catch (error) {
        formPurchaseChallenge.setFieldsValue({ promo_code: "" });
        if (error?.response?.data?.code) {
          toast.error({
            message: "",
            description: (error?.response?.data?.message && (error.response.data.message).length > 0) ? error.response.data.message : "Invalid Promo Code.",
            placement: "top",
          });
        } else {
          toast.error({
            message: "",
            description: "Invalid Promo Code.",
            placement: "top",
          });
        }
        console.error("An error occurred during check promo code:", error);
      }
    } else {
      toast.error({
        message: "",
        description: "Please input promo code.",
        placement: "top",
      });
    }
  };

  const handleCancelPromoCode = (e) => {
    e.preventDefault();
    formPurchaseChallenge.setFieldsValue({ promo_code: "" });
    setIsApplyPromoCode(false);
    setPromoCodeDeductAmount(0);
    handleCalculatePricePay();
    toast.info({
      message: "",
      description: "Promo code cancelled successfully!",
      placement: "top",
    });
  };

  useEffect(() => {
    // fetchDataAndSetProfileData();
    setLoggedIn(checkLoggedIn());
    getAllChallengesData();
    handleGetPaymentMethodWithCurrencies();

    if (referral) {
      formPurchaseChallenge.setFieldsValue({
        affiliate_code: referral ?? "",
      });
    }

    // handleUrlParams();
  }, []);

  useEffect(() => {
    handleUrlParams();

    if (selectedChallengeData) {
      if (Object.keys(selectedChallengeData).length > 0) {
        let challengeFound = allChallengeData[selectedChallengeData.challenge_type];
        if (!challengeFound) {
          setSelectedChallengeData({});
          setTradingCapitalAddOnsData({}); 
        }
      }
    }
  },[allChallengeData])

  useEffect(() => {
    if (urlParamsObj["challengeId"] && allChallengeData) {
      handleSelectedChallengeData(
        parseInt(urlParamsObj["challengeId"]),
        "trading_capital_id"
      );
    }
  }, [urlParamsObj, allChallengeData]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchDataAndSetProfileData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (triggerGetTradingCapitalData) {
      handleGetSelectedTradingCapitalData();
    }

    if (triggerCalculatePricePay) {
      handleCalculatePricePay();
    }
  }, [selectedChallengeData, triggerGetTradingCapitalData]);

  useEffect(() => {
    if (isLoggedIn) {
      formPurchaseChallenge.setFieldsValue({
        first_name: profileInfo?.user?.first_name ?? "-",
        last_name: profileInfo?.user?.last_name ?? "-",
        email_address: profileInfo?.user?.email ?? "-",
        phone_number: profileInfo?.user?.mobile ?? "-",
        address_line: profileInfo?.profile?.address ?? "-",
        city: profileInfo?.user?.city ?? "-",
        state: profileInfo?.user?.state ?? "-",
        country: profileInfo?.user?.country ?? "",
        postal_code: profileInfo?.profile?.zip ?? "-",
      });

      if (profileInfo?.user?.country) {
        let tempCountryCode = getCountryCodeByName(profileInfo.user.country);
        let stateList = getStateList(tempCountryCode);
        setFilteredStateList(stateList);
      }

      if (profileInfo?.user?.country && profileInfo?.user?.state) {
        let tempCountryCode = getCountryCodeByName(profileInfo.user.country);
        let tempStateCode = getStateCodeByName(
          tempCountryCode,
          profileInfo.user.state
        );
        let cityList = getCityList(tempCountryCode, tempStateCode);
        setFilteredCityList(cityList);
      }
    }
  }, [profileInfo]);

  // useEffect(() => {
  //     console.log(formInfo);

  // }, [formInfo]);

  useEffect(() => {
    const newStateList = !!selectedCountryCode
      ? getStateList(selectedCountryCode)
      : [];
    setFilteredStateList(newStateList);
  }, [selectedCountryCode]);

  useEffect(() => {
    const newCityList =
      !!selectedCountryCode && selectedStateCode
        ? getCityList(selectedCountryCode, selectedStateCode)
        : [];
    setFilteredCityList(newCityList);
  }, [selectedCountryCode, selectedStateCode]);

  // useEffect(() => {
  //     if (referral) {
  //         formPurchaseChallenge.setFieldsValue({
  //             affiliate_code: referral ?? "",
  //         });
  //     }
  // }, []);

  const handleStep = (idx) => {
    let newIndex = (parseInt(idx) + 1);
    if (newIndex == activeStep || newIndex > activeStep) {
      return false;
    }

    setActiveStep(newIndex);
  }

  return (
    <>
      {contextHolder}
      {modalContextHolder}
      <Modal
        id="loginModal"
        title="Login TradersFlow"
        open={isLoginModalOpen}
        onOk={handleOk}
        okButtonProps={{ className: "modal-ok-button" }}
        okText="Login Now"
        onCancel={handleCancel}
      >
        <Form
          form={formLogin}
          name="form_login"
          onFinish={(values) => handleLoginForm(values, formLogin)}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="login_email_address"
                label={
                  <span>
                    Email Address<span style={{ color: "red" }}> *</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Email Address is required",
                  },
                ]}
              >
                <Input
                  placeholder="Email Address"
                  className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="login_password"
                label={
                  <span>
                    Password<span style={{ color: "red" }}> *</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <div className="purchase-challenge-page box-border max-w-7xl mx-auto py-[30px] px-[20px] xl:px-[0]">
        <div className="mb-4">
          <div className="text-3xl font-semibold">Trading Challenge.</div>
          <div className="text-3xl font-semibold">On Your Terms.</div>
          <div className="text-base">
            Easier, or cheaper? Customize our TradersFlow Challenge to your
            trading style!
          </div>
        </div>
        <Form
          form={formPurchaseChallenge}
          name="purchase_challenge"
          onFinish={(values) => onClickNextStep(values, formPurchaseChallenge)}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          requiredMark={false}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={16}>
              <div className="grid gap-3 grid-cols-1 sm:grid-cols-3 mb-3">
                {steps.map((value, index) => {
                  return (
                    <div
                      key={index} 
                      className={`p-4 rounded-xl step step-1 cursor-pointer ${
                        activeStep === value?.order ? "active" : ""
                      }`}
                      onClick={() => handleStep(index)}
                    >
                      <div className="step-order mb-3">{value?.order}</div>
                      <div className="step-title">{value?.label}</div>
                    </div>
                  );
                })}
              </div>

              {activeStep === 1 && (
                <>
                  {/* New Challenge */}
                  <div className="bg-white rounded-2xl mb-4">
                    <div className="p-[20px]">
                      <div className="font-semibold text-lg">New Challenge</div>
                    </div>
                    <hr />
                    <div className="py-[20px] px-10">
                      <div className="mb-3">
                        <div className="font-medium text-lg mb-3">
                          Challenge Type
                        </div>
                        <Row gutter={[16, 16]}>
                          {Object.keys(allChallengeData).map((key, index) => (
                            <Col key={index} xs={24} sm={12}>
                              <div
                                className={`checkbox-card cursor-pointer rounded-lg p-5 ${
                                  selectedChallengeData?.challenge_type === key
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (
                                    !selectedChallengeData ||
                                    selectedChallengeData.challenge_type !== key
                                  ) {
                                    handleSelectedChallengeData(
                                      key,
                                      "challenge_type"
                                    );
                                  }
                                }}
                              >
                                {selectedChallengeData?.challenge_type ===
                                key ? (
                                  <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                    <CheckOutlined className="text-[#DD0744]" />
                                  </div>
                                ) : (
                                  <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center"></div>
                                )}
                                <div
                                  className={`text-base mt-2 ${
                                    selectedChallengeData?.challenge_type ===
                                    key
                                      ? "text-white"
                                      : "text-black"
                                  }`}
                                >
                                  {displayChallengeType(key)}
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                      {!!selectedChallengeData?.challenge_type &&
                        !!allChallengeData[
                          selectedChallengeData?.challenge_type
                        ]?.length && (
                          <div className="mb-3">
                            <div className="font-medium text-lg mb-3">
                              Trading Capital
                            </div>
                            <Row gutter={[16, 16]}>
                              {allChallengeData[
                                selectedChallengeData?.challenge_type
                              ].map((value, index) => {
                                return (
                                  <Col key={index} xs={24} sm={12}>
                                    <div
                                      className={`checkbox-card cursor-pointer rounded-lg p-5 ${
                                        value?.id
                                      } ${
                                        selectedChallengeData?.trading_capital_id ===
                                        value?.id
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (
                                          !selectedChallengeData ||
                                          selectedChallengeData.trading_capital_id !==
                                            value?.id
                                        ) {
                                          handleSelectedChallengeData(
                                            value?.id,
                                            "trading_capital_id"
                                          );
                                        }
                                      }}
                                    >
                                      {selectedChallengeData?.trading_capital_id ===
                                      value?.id ? (
                                        <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                          <CheckOutlined className="text-[#DD0744]" />
                                        </div>
                                      ) : (
                                        <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center"></div>
                                      )}
                                      <div
                                        className={`text-base mt-2 ${
                                          selectedChallengeData?.trading_capital_id ===
                                          value?.id
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        $
                                        {value?.account_balance
                                          ? value.account_balance.toLocaleString()
                                          : ""}
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        )}
                      {selectedChallengeData?.challenge_type &&
                        !!samplePlatFormData?.length && (
                          <div className="mb-3">
                            <div className="font-medium text-lg mb-3">
                              Platform
                            </div>
                            <Row gutter={[16, 16]}>
                              {samplePlatFormData.map((value, index) => {
                                return (
                                  <Col key={index} xs={24} sm={12}>
                                    <div
                                      className={`checkbox-card cursor-pointer rounded-lg p-5 ${
                                        selectedChallengeData?.platform ===
                                        value
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (
                                          !selectedChallengeData ||
                                          selectedChallengeData.platform !==
                                            value
                                        ) {
                                          handleSelectedChallengeData(
                                            value,
                                            "platform"
                                          );
                                        }
                                      }}
                                    >
                                      {selectedChallengeData?.platform ===
                                      value ? (
                                        <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                          <CheckOutlined className="text-[#DD0744]" />
                                        </div>
                                      ) : (
                                        <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center"></div>
                                      )}
                                      <div
                                        className={`text-base mt-2 ${
                                          selectedChallengeData?.platform ===
                                          value
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        {value}
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Add-Ons */}
                  {Object.keys(tradingCapitalAddOnsData).length > 0 && (
                    <div className="bg-white rounded-2xl mb-4">
                      <div className="p-[20px]">
                        <div className="font-semibold text-lg">Add-Ons</div>
                      </div>
                      <hr />
                      <div className="py-[20px] px-10">
                        <>
                          {Object.keys(tradingCapitalAddOnsData).map(
                            (key, index) => {
                              let addOnsTypeData =
                                tradingCapitalAddOnsData[key];
                              return (
                                <div key={index} className="mb-3">
                                  <div className="font-medium text-lg mb-3">
                                    {keyTextToLabel(key)}
                                  </div>
                                  <Row gutter={[16, 16]}>
                                    {addOnsTypeData.map((value2, index2) => {
                                      let isPercentage = false;
                                      let isDay = false;
                                      let addOnName = value2?.name ?? "";
                                      if (addOnName.includes("%")) {
                                        isPercentage = true;
                                      }
                                      if (addOnName.endsWith("days")) {
                                        isDay = true;
                                      }
                                      let addOnPrice = value2?.price ?? 0;

                                      return (
                                        <Col key={index2} xs={24} sm={12}>
                                          <div
                                            className={`checkbox-card cursor-pointer rounded-lg p-4 ${
                                              selectedChallengeData?.addons?.[
                                                key
                                              ]?.id &&
                                              selectedChallengeData?.addons?.[
                                                key
                                              ]?.id === value2?.id
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (
                                                !(
                                                  key ===
                                                  "profit_target_step_two"
                                                )
                                              ) {
                                                if (
                                                  !selectedChallengeData ||
                                                  selectedChallengeData?.addons[
                                                    key
                                                  ]?.id !== value2?.id
                                                ) {
                                                  handleSelectedAddOnData(
                                                    value2?.id,
                                                    key,
                                                    index2
                                                  );
                                                }
                                              }
                                            }}
                                          >
                                            {selectedChallengeData?.addons?.[
                                              key
                                            ]?.id &&
                                            selectedChallengeData?.addons?.[key]
                                              ?.id === value2?.id ? (
                                              <div className="flex justify-between">
                                                <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                                  <CheckOutlined className="text-[#DD0744]" />
                                                </div>
                                                {addOnPrice > 0 && (
                                                  <div className="bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#1D1E25]">
                                                    +$
                                                    {addOnPrice.toLocaleString()}
                                                  </div>
                                                )}
                                                {addOnPrice < 0 && (
                                                  <div className="bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#DD0744]">
                                                    -$
                                                    {Math.abs(
                                                      addOnPrice
                                                    ).toLocaleString()}
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="flex justify-between">
                                                <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center"></div>
                                                {addOnPrice > 0 && (
                                                  <div className="bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#1D1E25]">
                                                    +$
                                                    {addOnPrice.toLocaleString()}
                                                  </div>
                                                )}
                                                {addOnPrice < 0 && (
                                                  <div className="bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#DD0744]">
                                                    -$
                                                    {Math.abs(
                                                      addOnPrice
                                                    ).toLocaleString()}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            <div
                                              className={`text-base mt-2 ${
                                                selectedChallengeData?.addons?.[
                                                  key
                                                ]?.id === value2?.id
                                                  ? "text-white"
                                                  : "text-black"
                                              }`}
                                            >
                                              {(() => {
                                                let result;
                                                switch (key) {
                                                  case "profit_target_step_two":
                                                    result =
                                                      value2["profit_target"];
                                                    break;
                                                  default:
                                                    result = value2[key];
                                                    break;
                                                }
                                                return result;
                                              })()}
                                              {isPercentage
                                                ? "%"
                                                : isDay
                                                ? ` day${
                                                    +value2?.[key] > 0
                                                      ? "s"
                                                      : ""
                                                  }`
                                                : ""}
                                            </div>
                                          </div>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                  {key === "refundable_fee" && (
                                    <div className="text-gray text-xs pt-3">
                                      {refundableFeeHint}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </>
                      </div>
                    </div>
                  )}

                  {/* Apply Coupon */}
                  {selectedChallengeData &&
                    selectedChallengeData.challenge_type &&
                    selectedChallengeData.trading_capital_id && 
                    selectedChallengeData.trading_capital_id != 1 && (
                      <div className="bg-white rounded-2xl mb-4">
                        <div className="p-[20px]">
                          <div className="font-semibold text-lg">
                            Apply Coupon
                          </div>
                        </div>
                        <hr />
                        <div className="py-[20px] px-10">
                          <Row gutter={16}>
                            <Col span={24}>
                              <div className="flex items-center">
                                <Form.Item
                                  name="promo_code"
                                  className="w-full"
                                  label={<span>Promo Code</span>}
                                >
                                  <Input
                                    placeholder="Promo Code"
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                                    readOnly={isApplyPromoCode}
                                  />
                                </Form.Item>
                                <Button
                                  type="primary"
                                  className="custom-primary-button h-[50px] px-6 py-5 ml-4 mt-[12px] rounded-xl"
                                  onClick={(e) => {
                                    if (isApplyPromoCode) {
                                      handleCancelPromoCode(e);
                                    } else if (
                                      selectedChallengeData &&
                                      selectedChallengeData.challenge_type &&
                                      selectedChallengeData.trading_capital_id
                                    ) {
                                      handlePromoCode(e);
                                    }
                                  }}
                                >
                                  {isApplyPromoCode ? "Cancel" : "Apply"}
                                </Button>
                              </div>
                            </Col>
                            {/* <Col span={24}>
                                                        <div className="flex items-center">
                                                            <Form.Item
                                                                name="discount_code" 
                                                                className="w-full"
                                                                label={<span>Discount Code</span>} 
                                                                
                                                            >
                                                                <Input 
                                                                    placeholder="Discount Code" 
                                                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                                />
                                                            </Form.Item> 
                                                            <Button 
                                                                type="primary" 
                                                                className="custom-primary-button h-[50px] px-6 py-5 ml-4 mt-[12px] rounded-xl"  
                                                            >
                                                                Apply
                                                            </Button>
                                                        </div>
                                                    </Col> */}
                            {/* <Col span={24}>
                                                        <div className="flex items-center">
                                                            <Form.Item
                                                                name="affiliate_code" 
                                                                className="w-full"
                                                                label={<span>Affiliate Code</span>} 
                                                                
                                                            >
                                                                <Input 
                                                                    placeholder="Affiliate Code" 
                                                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                                />
                                                            </Form.Item> 
                                                            
                                                        </div>
                                                    </Col> */}
                          </Row>
                        </div>
                      </div>
                    )}

                  {/* Billing Info */}
                  <div className="bg-white rounded-2xl">
                    <div className="p-[20px] flex justify-between items-center">
                      <div className="font-semibold text-lg">Billing Info</div>
                      {!isLoggedIn && (
                        <div>
                          <Button
                            type="button"
                            onClick={handleShowLoginModal}
                            style={{
                              background:
                                "linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%)",
                            }}
                            className="custom-primary-button h-[30px] px-6 py-5 rounded-xl"
                          >
                            Login
                          </Button>
                        </div>
                      )}
                      {/* <Button 
                                                type="button" 
                                                onClick={handleSendVerificationCode}
                                                style={{ background: 'linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%)' }}
                                                className="custom-primary-button h-[50px] px-6 py-5 ml-4 mt-[12px] rounded-xl"  
                                            >
                                                Send Code
                                            </Button> */}
                    </div>
                    <hr />
                    <div className="py-[20px] px-10">
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="first_name"
                            label={
                              <span>
                                First Name
                                <span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "First Name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="First Name"
                              className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="last_name"
                            label={
                              <span>
                                Last Name
                                <span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Last Name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Last Name"
                              className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="email_address"
                            label={
                              <span>
                                Email Address
                                <span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Email Address is required",
                              },
                              {
                                type: "email",
                                message: "Please enter a valid email address",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Email Address"
                              className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="phone_number"
                            label={
                              <span>
                                Phone Number
                                <span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Phone Number is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Phone Number"
                              className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="address_line"
                            label={
                              <span>
                                Address Line
                                <span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Address Line is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Address Line"
                              className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name="country"
                            label={
                              <span>
                                Country<span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Country is required",
                              },
                            ]}
                          >
                            <Cascader
                              style={{ display: "block" }}
                              className="input-text"
                              placeholder="Country"
                              onChange={handleCountryChange}
                              showSearch={{ filter }}
                              multiple={false}
                              onSearch={(value) => console.log(value)}
                              options={getCountryList()?.map((x) => ({
                                label: x.name,
                                value: x.name,
                              }))}
                              dropdownMenuColumnStyle={{ paddingRight: 40 }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name="state"
                            label={
                              <span>
                                State<span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "State is required",
                              },
                            ]}
                          >
                            <Cascader
                              style={{ display: "block" }}
                              className="input-text"
                              placeholder="State"
                              onChange={handleStateChange}
                              showSearch={{ filter }}
                              multiple={false}
                              onSearch={(value) => console.log(value)}
                              options={filteredStateList.map((x) => ({
                                label: x.name,
                                value: x.name,
                              }))}
                              dropdownMenuColumnStyle={{ paddingRight: 40 }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name="city"
                            label={
                              <span>
                                city<span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "City is required",
                              },
                            ]}
                          >
                            {/* <Input 
                                                            placeholder="City" 
                                                            className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                        /> */}
                            <Cascader
                              style={{ display: "block" }}
                              className="input-text"
                              placeholder="City"
                              onChange={handleCityChange}
                              showSearch={{ filter }}
                              multiple={false}
                              onSearch={(value) => console.log(value)}
                              options={filteredCityList.map((x) => ({
                                label: x.name,
                                value: x.name,
                              }))}
                              dropdownMenuColumnStyle={{ paddingRight: 40 }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name="postal_code"
                            label={
                              <span>
                                Postal Code
                                <span style={{ color: "red" }}> *</span>
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Postal Code is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Postal Code"
                              className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                            />
                          </Form.Item>
                        </Col>

                        {/* referral */}
                        {!isLoggedIn && (
                          <Col xs={24} sm={12} lg={24}>
                            <Form.Item
                              name="affiliate_code"
                              label={<span>Affiliate Code</span>}
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Affiliate Code is required",
                              //     },
                              // ]}
                            >
                              <Input
                                placeholder="Affiliate Code"
                                className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                                disabled={referral ? true : false}
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                      {!isLoggedIn && (
                        <Row gutter={16}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="password"
                              label={
                                <span>
                                  Password
                                  <span style={{ color: "red" }}> *</span>
                                </span>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Password is required",
                                },
                              ]}
                            >
                              <Input.Password
                                placeholder="Password"
                                className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="confirm_password"
                              label={
                                <span>
                                  Confirm Password
                                  <span style={{ color: "red" }}> *</span>
                                </span>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Confirm Password is required",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        "The two passwords do not match!"
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              {/* <Input.Password placeholder="Confirm Password" /> */}
                              <Input.Password
                                placeholder="Confirm Password"
                                className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <div className="flex items-center">
                              <Form.Item
                                name="verification_code"
                                className="w-full"
                                label={
                                  <span>
                                    Verification Code
                                    <span style={{ color: "red" }}> *</span>
                                  </span>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Verification Code is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Verification Code"
                                  className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                                />
                              </Form.Item>
                              <Button
                                type="button"
                                onClick={handleSendVerificationCode}
                                style={{
                                  background:
                                    "linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%)",
                                }}
                                className="custom-primary-button h-[50px] px-6 py-5 ml-4 mt-[12px] rounded-xl"
                              >
                                Send Code
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </>
              )}

              {activeStep === 2 && (
                <>
                  <Row gutter={16} className="mb-3">
                    <Col xs={24} sm={12} className="m-auto">
                      <div className="bg-black rounded-lg p-4 text-white">
                        <div className="grid gap-2 grid-cols-2 ">
                          <div className="text-[rgba(255,255,255,.7)]">
                            Challenge Type
                          </div>
                          <div className="text-right">
                            {selectedChallengeData?.title}
                          </div>

                          <div className="text-[rgba(255,255,255,.7)]">
                            Account Balance
                          </div>
                          <div className="text-right">
                            ${selectedChallengeData?.account_balance}
                          </div>

                          <div className="text-[rgba(255,255,255,.7)]">
                            Platform
                          </div>
                          <div className="text-right">
                            {selectedChallengeData?.platform}
                          </div>
                        </div>
                        <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                        <div>
                          <div className="text-[rgba(255,255,255,.7)] mb-2">
                            AddOns
                          </div>
                          <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
                            {!!selectedChallengeData?.addons &&
                              Object.keys(selectedChallengeData.addons).length >
                                0 && (
                                <>
                                  {Object.keys(
                                    selectedChallengeData.addons
                                  ).map((key, index) => {
                                    let addOnsTypeData =
                                      selectedChallengeData.addons[key];

                                    return (
                                      <div key={index}>
                                        <p>{addOnsTypeData?.name}</p>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                          </div>
                        </div>
                        <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                        <div className="grid gap-2 grid-cols-2 ">
                          <div className="text-[rgba(255,255,255,.7)]">
                            Price
                          </div>
                          <div className="text-right">
                            {pricePay
                              ? "$" +
                                pricePay.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })
                              : "$0.00"}
                          </div>

                          <div className="text-[rgba(255,255,255,.7)]">
                            Promo Code
                          </div>
                          <div className="text-right">
                            {"-$" +
                              promoCodeDeductAmount.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                          </div>

                          <div className="text-[rgba(255,255,255,.7)]">
                            Total
                          </div>
                          <div className="text-right">
                            {pricePay
                              ? "$" +
                                (pricePay - 0).toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })
                              : "$0.00"}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {activeStep === 3 && (
                <>
                  {selectedChallengeData?.trading_capital_id != 1 && (
                    <div className="bg-white rounded-2xl mb-4">
                      <div className="p-[20px]">
                        <div className="font-semibold text-lg">
                          Payment Method
                        </div>
                      </div>
                      <hr />
                      <div className="py-[20px] px-10 min-h-[180px]">
                        {paymentMethods && (
                          <Form.Item
                            name="radioPaymentMethod"
                            label={""}
                            rules={[
                              {
                                required: true,
                                message: "Payment Method is required",
                              },
                            ]}
                          >
                            <Radio.Group
                              onChange={onChangePaymentMethod}
                              value={selectedPaymentMethod?.id}
                            >
                              {paymentMethods.map((value, index) => {
                                return (
                                  <Radio
                                    key={index}
                                    value={value.id}
                                    className="mb-2"
                                  >
                                    {value.name}
                                  </Radio>
                                );
                              })}
                            </Radio.Group>
                          </Form.Item>
                        )}

                        {selectedPaymentMethod &&
                          selectedPaymentMethod
                            ?.payment_method_supported_currencies?.length > 0 && (
                            <>
                              <div className="font-medium text-lg mb-3">
                                Currency
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4">
                                {selectedPaymentMethod.payment_method_supported_currencies.map(
                                  (value, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`checkbox-card cursor-pointer rounded-lg p-5 ${
                                          selectedPaymentMethodCurrency?.currency_id ===
                                          value?.currency_id
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          if (
                                            !selectedPaymentMethodCurrency ||
                                            selectedPaymentMethodCurrency.currency_id !==
                                              value?.currency_id
                                          ) {
                                            handleSelectedPaymentMethodCurrency(
                                              value?.currency_id
                                            );
                                          }
                                        }}
                                      >
                                        {selectedPaymentMethodCurrency?.currency_id ===
                                        value?.currency_id ? (
                                          <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                            <CheckOutlined className="text-[#DD0744]" />
                                          </div>
                                        ) : (
                                          <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center"></div>
                                        )}

                                        <div
                                          className={`text-base mt-2 ${
                                            selectedPaymentMethodCurrency?.currency_id ===
                                            value?.currency_id
                                              ? "text-white"
                                              : "text-black"
                                          }`}
                                        >
                                          {value?.currency?.unit}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  )}
                  <div className="bg-white rounded-2xl mb-4">
                    <div className="p-[20px]">
                      <div className="font-semibold text-lg">
                        Your Order Review
                      </div>
                    </div>
                    <hr />
                    <div className="py-[20px] px-10">
                      <Row gutter={16} className="mb-3">
                        <Col span={24} className="m-auto">
                          <div className="text-black p-4">
                            <div className="grid gap-2 grid-cols-2 ">
                              <div className="text-[rgba(0,0,0,.7)]">
                                Challenge Type
                              </div>
                              <div className="text-right">
                                {selectedChallengeData?.title}
                              </div>

                              <div className="text-[rgba(0,0,0,.7)]">
                                Account Balance
                              </div>
                              <div className="text-right">
                                ${selectedChallengeData?.account_balance}
                              </div>

                              <div className="text-[rgba(0,0,0,.7)]">
                                Platform
                              </div>
                              <div className="text-right">
                                {selectedChallengeData?.platform}
                              </div>
                            </div>
                            <hr className="my-4 border-[rgba(0,0,0,.7)]" />
                            <div>
                              <div className="text-[rgba(0,0,0,.7)] mb-2">
                                AddOns
                              </div>
                              <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
                                {!!selectedChallengeData?.addons &&
                                  Object.keys(selectedChallengeData.addons)
                                    .length > 0 && (
                                    <>
                                      {Object.keys(
                                        selectedChallengeData.addons
                                      ).map((key, index) => {
                                        let addOnsTypeData =
                                          selectedChallengeData.addons[key];

                                        return (
                                          <div key={index}>
                                            <p>{addOnsTypeData?.name}</p>
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                              </div>
                            </div>
                            <hr className="my-4 border-[rgba(0,0,0,.7)]" />
                            <div className="grid gap-2 grid-cols-2 ">
                              <div className="text-[rgba(0,0,0,.7)]">Price</div>
                              <div className="text-right">
                                {pricePay
                                  ? "$" +
                                    pricePay.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })
                                  : "$0.00"}
                              </div>

                              <div className="text-[rgba(0,0,0,.7)]">
                                Promo Code
                              </div>
                              <div className="text-right">
                                {"-$" +
                                  promoCodeDeductAmount.toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                              </div>

                              <div className="text-[rgba(0,0,0,.7)]">Total</div>
                              <div className="text-right">
                                {pricePay
                                  ? "$" +
                                    (pricePay - 0).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })
                                  : "$0.00"}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              )}
            </Col>
            <Col xs={24} xl={8}>
              <div
                className={`${
                  activeStep === 1 || activeStep === 3
                    ? "relative top-auto xl:sticky xl:top-[0]"
                    : ""
                }`}
              >
                <div className={`bg-black rounded-xl px-8 py-6 mb-4`}>
                  <div className="bg-white bg-opacity-20 py-3 rounded-xl leading-7 flex items-center flex-col">
                    <div className="text-[rgba(255,255,255,.8)] mb-1">
                      Your total price
                    </div>
                    <div className="text-white font-bold text-4xl min-h-10">
                      {"$" +
                        pricePay.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </div>
                  </div>
                  <div className="h-4"></div>
                  {activeStep === 1 && (
                    <>
                      <div className="text-white">
                        <div>By confirming the order</div>
                        <div>
                          <Form.Item
                            name="read_agreement"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        "Please tick you have read all agreement."
                                      ),
                              },
                            ]}
                          >
                            <Checkbox className="text-white">
                              I have read all agreements. Privacy, Risk
                              Disclosure, Terms and Condition, Refund Policy
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="w-9/12 m-auto">
                          <Button
                            type="primary"
                            className="custom-primary-button h-[50px] px-6 py-5 w-full rounded-xl"
                          >
                            Continue Now
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === 2 && (
                    <div className="pt-2">
                      <div className="w-9/12 m-auto">
                        <Button
                          type="primary"
                          className="custom-primary-button h-[50px] px-6 py-5 w-full rounded-xl"
                        >
                          Finalize Your Order
                        </Button>
                      </div>
                    </div>
                  )}

                  {activeStep === 3 && (
                    <div className="pt-2">
                      <div className="w-9/12 m-auto">
                        <Button
                          type="primary"
                          className="custom-primary-button h-[50px] px-6 py-5 w-full rounded-xl"
                        >
                          Confirm Order
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="bg-white rounded-2xl mb-4 p-4">
                  <div className="font-semibold text-lg">
                    Included with your plan
                  </div>
                  <Row gutter={16} className="leading-7">
                    <Col xs={24} md={12}>
                      <CheckCircleFilled className="text-[#DD0744]" />
                      <span className="ml-2">No Time Limit</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <CheckCircleFilled className="text-[#DD0744]" />
                      <span className="ml-2">Raw Spreads</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <CheckCircleFilled className="text-[#DD0744]" />
                      <span className="ml-2">Law Commissions</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <CheckCircleFilled className="text-[#DD0744]" />
                      <span className="ml-2">Hold Over Weekend</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <CheckCircleFilled className="text-[#DD0744]" />
                      <span className="ml-2">Regulated Broker</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <CheckCircleFilled className="text-[#DD0744]" />
                      <span className="ml-2">Secure Payouts</span>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="flex justify-start items-center pt-4 w-8/12">
                        <div className="mr-2">
                          <Image
                            src="/images/Visa.png"
                            alt="Visa"
                            className="w-full"
                            preview={false}
                          />
                        </div>
                        <div className="mr-2">
                          <Image
                            src="/images/Mastercard.png"
                            alt="Visa"
                            className="w-full"
                            preview={false}
                          />
                        </div>
                        <div className="mr-2">
                          <Image
                            src="/images/Maestro.png"
                            alt="Visa"
                            className="w-full"
                            preview={false}
                          />
                        </div>
                        <div className="mr-2">
                          <Image
                            src="/images/logo_tether_with_text.png"
                            alt="Visa"
                            className="w-full"
                            preview={false}
                          />
                        </div>
                        {/* <div className='mr-2'>
                                                    <Image
                                                        src="/images/bitcoin.png"
                                                        alt="Visa"
                                                        className="w-full"
                                                        preview={false}
                                                    />
                                                </div>
                                                <div className='mr-2'>
                                                    <Image
                                                        src="/images/ethereum.png"
                                                        alt="Visa"
                                                        className="w-full "
                                                        preview={false}
                                                    />
                                                </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default PurchaseChallenge;
